import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isBootstrapped: boolean;
}

const initialState: State = {
  isBootstrapped: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsBootstrapped: (state, action: PayloadAction<boolean>) => {
      state.isBootstrapped = action.payload;
    },
  },
});

export const { setIsBootstrapped } = appSlice.actions;

export default appSlice.reducer;
