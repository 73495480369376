import Image from "../assets/logo.png";
import { scrollToPage } from "../utils";
import Instagram from "../assets/icons/instagram.svg";
import Linkedin from "../assets/icons/linkedin.svg";
import { useMediaQuery } from "@mui/material";

function Contact() {

  const isPhone = useMediaQuery('(max-width: 425px)')

  return (
    <div className="footer-container">
      <div className="footer-top-container">
        <div className="social-media">
          <img src={Image} alt="" />
          <div className="some-links">
            <a href="https://www.linkedin.com/company/vrestate/" target="_blank" className="logo-container" rel="noreferrer">
              <img src={Linkedin} alt="linkedin logo" />
            </a>
            <a
              href="https://instagram.com/vrestate_official?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
              target="_blank"
              className="logo-container" rel="noreferrer"
            >
              <img style={{ width: 40, height: 40 }} src={Instagram} alt="instagram logo" />
            </a>
          </div>
        </div>

        <div className="links">
          <p className="header">LINKS</p>
          <li onClick={() => scrollToPage("frontpage")}>Forside</li>
          <li onClick={() => scrollToPage("about")}>Om os</li>
          <li onClick={() => scrollToPage("projects")}>Projekter</li>
          <li onClick={() => scrollToPage("proces")}>Proces</li>
          <li onClick={() => scrollToPage("vision")}>Værdier</li>
          <li onClick={() => scrollToPage("contact")}>Kontakt</li>
        </div>

        <div className="contact">
          <p className="header">KONTAKT OS</p>
          <a href="tel:6031112298">+45 22 51 55 63</a>
          <a href="mailto: kontakt@vr-estate.dk">
            KONTAKT<span>@</span>VR-ESTATE.DK
          </a>
        </div>
      </div>
      <div className="line"></div>

      <div className="footer-bottom-container">
        {
          isPhone ?
            <>
              <p>Copyright © Alle rettigheder forbeholdes. </p>
              <p>Designet af VR Estate.</p>
            </>
            :
            <p>Copyright © Alle rettigheder forbeholdes. Designet af VR Estate.</p>
        }
        <p>CVR: 44 25 27 91</p>
      </div>
    </div>
  );
}

export default Contact;
