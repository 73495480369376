const Estate = () => {
  return (
    <div style={{ height: "100svh" }}>
      <iframe
        class="captur3d-3d-tour__iframe"
        width="100%"
        height="100%"
        src="https://vr-estate.captur3d.io/view/estate-strandstoft/fritidshus-4-vaerelses-pa-89m"
        frameborder="0"
        allowfullscreen=""
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        onmousewheel=""
        allow="xr-spatial-tracking; clipboard-write"
      ></iframe>
    </div>
  );
};

export default Estate;
