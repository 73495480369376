// @ts-nocheck
import { useSearchParams } from "react-router-dom";

function Preview() {
  const [searchParams] = useSearchParams();

  return (
    <div style={{ height: "100svh" }}>
      <iframe
        width="100%"
        height="100%"
        src={`https://vr-estate.captur3d.io/view/${searchParams.get("id") ?? ""}`}
        frameborder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
      ></iframe>
    </div>
  );
}

export default Preview;
