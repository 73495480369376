import { Collapse, Divider } from "@mui/material";
import VisionImage from "../assets/images/vision.jpg";
import { useState } from "react";

const Vision = () => {

  const [expand, setExpand] = useState(true);
  const [data, setData] = useState([
    {
      header: 'Kreative teknologi-nørder',
      text: 'Vores team består af kreative sind, der forstår vigtigheden af at kombinere teknologi med kunst. Fra panoramaoptagelser til 3D modellering, arbejder vores team af designeksperter sammen for at skabe fængslende virtuelle ture, der vil inspirere dine kunder. Vi har dedikerede teknologiske specialister, der er mestre i 360-graders teknologi og virtuelle platforme. De sørger for, at vores virtuelle ture fungerer sømløst på alle enheder og skaber den mest realistiske oplevelse mulig.',
      show: false
    },
    {
      header: 'Ejendomseksperter',
      text: 'Vores team inkluderer også medlemmer med dybdegående viden om ejendomsbranchen. De forstår de unikke udfordringer og muligheder, som ejendomsmarkedet præsenterer, og kan tilpasse vores løsninger til dine specifikke behov. Hos VR Estate ser vi fremtiden og vi tror på, at den virtuelle ejendomsverden kun vil vokse. Vores visionære team arbejder konstant på at forblive i frontlinjen af denne udvikling, så du kan have tillid til, at du arbejder med branchens førende eksperter.',
      show: false
    },
    {
      header: 'Kundesupport',
      text: 'Vi er her for dig! Vores kundesupport-team er klar til at besvare dine spørgsmål, tilbyde teknisk support og hjælpe dig med at få mest muligt ud af vores tjenester. Din succes er vores fornøjelse. Tøv ikke med at kontakte os!',
      show: false
    },
  ])

  const expandHandler = (index: number) => {
    const updated = data.map((obj, i) => {
      if (index === i) {
        if (obj.show) {
          return {
            ...obj,
            show: false
          }
        }
        return {
          ...obj,
          show: true
        }
      } else {
        return {
          ...obj,
          show: false
        }
      }
    })
    setData(updated);
  }

  return (
    <div className="vision-container">
      <div className="left-container">
        <img src={VisionImage} alt='' />
      </div>

      <div className="right-container">
        <h2 id='vision'>VR ESTATES MINDSET</h2>
        <h1>Lær mere om VR Estates vision & værdier </h1>
        {
          data.map((obj, index) =>
            <div className="expandable-header-container" onClick={() => expandHandler(index)}>
              <div className="expanadable-header-subcontainer">
                <h3>
                  {index + 1}. {obj.header}
                </h3>
                <div className="expand-symbol">
                  {
                    obj.show ? '-' : '+'
                  }
                </div>
              </div>
              <Collapse in={obj.show} timeout={600} unmountOnExit>
                <p className="expandable-text">
                  {obj.text}
                </p>
              </Collapse>
            </div>
          )
        }
      </div>
    </div >
  );
}

export default Vision;
