import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Tour } from '../pages/Projects';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { addTour, storage } from '../firebase';

interface Props {
  editingTour?: Tour;
  open: boolean;
  setOpen: (show: boolean) => void;
  updateTourCallback: (tour: Tour) => void;
  addTourCallback: (tour: Tour) => void;
  tours: Tour[]
}

export default function CreateEditTourDialog(props: Props) {
  const { editingTour, open, setOpen, updateTourCallback, addTourCallback, tours } = props;

  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [src, setSrc] = useState('');
  const [showOnDesktop, setShowOnDesktop] = useState(true);
  const [showOnMobile, setShowOnMobile] = useState(true);

  useEffect(() => {
    setTitle(props.editingTour?.title ?? '');
    setImage(props.editingTour?.image ?? '');
    setSubtitle(props.editingTour?.subtitle ?? '');
    setSrc(props.editingTour?.src ?? '');
    setShowOnDesktop(props.editingTour?.showOnDesktop ?? true);
    setShowOnMobile(props.editingTour?.showOnMobile ?? true);
  }, [props.editingTour?.image, props.editingTour?.showOnDesktop, props.editingTour?.showOnMobile, props.editingTour?.src, props.editingTour?.subtitle, props.editingTour?.title])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    if (editingTour) {
      if (
        title !== props.editingTour?.title ||
        image !== props.editingTour?.image ||
        subtitle !== props.editingTour?.subtitle ||
        src !== props.editingTour?.src ||
        showOnDesktop !== props.editingTour?.showOnDesktop ||
        showOnMobile !== props.editingTour?.showOnMobile
      ) return false
      return true;
    } else {
      if (title && image && subtitle && src) return false
      return true
    }
  }


  const uploadFile = (file: any) => {

    const storageRef = ref(storage, `tours/${file.name}.jpg`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.error(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL)
        });
      }
    );
  }

  const save = () => {
    if (!props.editingTour?.id) return;

    const newTour = {
      id: props.editingTour?.id,
      image,
      showOnDesktop,
      showOnMobile,
      src,
      subtitle,
      title,
      order: props.editingTour.order,
      showOnFrontpage: props.editingTour.showOnFrontpage
    } as Tour

    updateTourCallback(newTour);
  }


  const addTourHandler = () => {
    const newTour = {
      image,
      showOnDesktop,
      showOnMobile,
      src,
      subtitle,
      title,
      order: tours.length + 1,
      showOnFrontpage: false
    } as Tour;

    addTour(newTour).then((id: string) => {
      addTourCallback(
        {
          id,
          ...newTour
        }
      )
    })
  }

  return (
    <Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Opret tour
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{editingTour ? 'Opdater tour' : 'Opret tour'}</DialogTitle>
        <DialogContent>
          <>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              type="file"
              id="upload-button-file"
              onChange={(e) => uploadFile(e.target.files![0])}
            />
            <label htmlFor="upload-button-file">
              {image ? <img style={{ maxHeight: '100px', maxWidth: '200px' }} src={image} alt='' /> : <p className='upload-button'>Upload billede</p>}
            </label>
          </>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Navn"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <TextField
            required
            margin="dense"
            id="description"
            name="description"
            label="Beskrivelse"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setSubtitle(e.target.value)}
            value={subtitle}
          />
          <TextField
            required
            margin="dense"
            id="link"
            name="Link"
            label="Link"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setSrc(e.target.value)}
            value={src}
          />
          <FormControlLabel control={<Checkbox checked={showOnDesktop} onChange={(e) => setShowOnDesktop(e.target.checked)} />} label="Vis på desktop" />
          <FormControlLabel control={<Checkbox checked={showOnMobile} onChange={(e) => setShowOnMobile(e.target.checked)} />} label="Vis på mobil" />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuller</Button>
          <Button onClick={editingTour ? save : addTourHandler} disabled={validate()} type="submit">{editingTour ? 'Opdater' : 'Opret'}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}