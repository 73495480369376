import Service, { ServiceType } from "../components/Service";
import Afstandsmåler from "../assets/icons2/Afstandsmåler.png";
import Dollhouse from "../assets/icons2/Dollhouse.png";
import Labels from "../assets/icons2/Labels.png";
import Navigation from "../assets/icons2/Navigation.png";
import Plantegning from "../assets/icons2/Plantegning.png";
import StreetView from "../assets/icons2/StreetView.png";
import VirtualTour from "../assets/icons2/VirtualTour.png";
import Branding from "../assets/icons2/Branding.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from "@mui/material";


const services: Array<ServiceType> = [
  {
    title: "Virtual Tour",
    description: "360 graders interaktiv virtuel fremvisning‍",
    imageSource: VirtualTour,
  },
  {
    title: "Dollhouse View",
    description: "Bevægelig 3D Model af ejendommen‍",
    imageSource: Dollhouse,
  },
  {
    title: "3D Plantegning",
    description: "Plantegning med satellit billeder‍",
    imageSource: Plantegning,
  },
  {
    title: "Navigation",
    description: "Brugervenlig interaktion med hotspots & minimap‍",
    imageSource: Navigation,
  },
  {
    title: "Afstandsmåler",
    description: "Beregn dimensionerne med virtuelt målebånd",
    imageSource: Afstandsmåler,
  },
  {
    title: "Labels",
    description: "Tilføj tekst, links, billeder & videoer",
    imageSource: Labels,
  },
  {
    title: "Branding",
    description: "Tilføj din virksomheds logo på projektet",
    imageSource: Branding,
  },
  {
    title: "Street View",
    description: "Mulighed for upload til Google Street View",
    imageSource: StreetView,
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Services() {

  const isPhone = useMediaQuery('(max-width: 425px)')

  return (
    <div className="services-container">
      {
        isPhone ? <Carousel responsive={responsive} infinite autoPlay autoPlaySpeed={4000}>
          {services.map((service, index) => (
            <Service
              key={service.title + index}
              description={service.description}
              imageSource={service.imageSource}
              title={service.title}
            />
          ))}
        </Carousel>
          :
          <>
            {services.map((service, index) => (
              <Service
                key={service.title + index}
                description={service.description}
                imageSource={service.imageSource}
                title={service.title}
              />
            ))}
          </>
      }
    </div>
  );
}

export default Services;
