const PCV = () => {
  return (
    <div style={{ height: "100svh" }}>
      <iframe
        width="100%"
        height="100%"
        src="https://my.matterport.com/show/?m=C75Cwb4mjiC"
        frameborder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
        onLoad={() => console.log("Hello")}
      ></iframe>
    </div>
  );
};

export default PCV;
