import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import { scrollToPage } from "../utils";
import styled from "@emotion/styled";

function MobileMenu() {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const isPhone = useMediaQuery('(max-width: 425px)')

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = (id: string) => {
        scrollToPage(id);
        handleClose();
    }

    useEffect(() => {
        document.addEventListener('touchmove', handleClose)
        return () => document.removeEventListener('touchmove', handleClose)
    })

    return (
        <div className="menu-container">
            <img
                className="logo"
                src={Logo}
                alt=""
                onClick={isPhone ? handleClick : undefined}
            />
            <Menu
                disableScrollLock
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <StyledMenuItem onClick={() => navigate('frontpage')}>Forside</StyledMenuItem>
                <StyledMenuItem onClick={() => navigate('about')}>Om os</StyledMenuItem>
                <StyledMenuItem onClick={() => navigate('projects')}>Projekter</StyledMenuItem>
                <StyledMenuItem onClick={() => navigate('proces')}>Proces</StyledMenuItem>
                <StyledMenuItem onClick={() => navigate('vision')}>Værdier</StyledMenuItem>
                <StyledMenuItem onClick={() => navigate('contact')}>Kontakt</StyledMenuItem>
            </Menu>
        </div>
    );
}

const StyledMenuItem = styled(MenuItem)`
    color: #bfa47e;
`

export default MobileMenu;
