import Image from "../assets/images/contact.png";

function Contact() {
  return (
    <div className="contact-container">
      <div className="text-container">
        <h1 id="contact" >Kontakt os for en uforpligtende snak!</h1>
        <h2>Ring nu!</h2>
        <a href="tel:6031112298">+45 22 51 55 63</a>
        <br></br>
        <h2>Skriv nu!</h2>
        <a href="mailto: kontakt@vr-estate.dk">
          KONTAKT<span>@</span>VR-ESTATE.DK
        </a>
      </div>

      <img src={Image} alt="" />
    </div>
  );
}

export default Contact;
