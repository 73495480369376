import { useEffect, useState } from "react";
import Spisestue from "../assets/images/spisestue.jpg";
import CheckIcon from "@mui/icons-material/Check";
import { scrollToPage } from "../utils";

const shortVersion = (
  <>
    VR Estate er en nyopstartet virksomhed, der er dedikeret til at transformere ejendomsbranchen ved hjælp af banebrydende
    360-graders teknologi. Vi specialiserer os i at skabe realistiske virtuelle ture af ejendomme, der giver potentielle
    købere og lejere mulighed for at udforske ejendomme på en realistisk måde, alt fra komforten af deres eget hjem.
  </>
);

const longVersion = (
  <>
    VR Estate er en nyopstartet virksomhed, der er dedikeret til at transformere ejendomsbranchen ved hjælp af banebrydende
    360-graders teknologi. Vi specialiserer os i at skabe realistiske virtuelle ture af ejendomme, der giver potentielle
    købere og lejere mulighed for at udforske ejendomme på en realistisk måde, alt fra komforten af deres eget hjem.
    <br></br>
    <br></br>
    Vores mission er enkel: At hjælpe ejendomsmæglere og boligudlejere med at forbedre deres online ejendomspræsentation,
    samtidig med at vi sparer tid og ressourcer for både sælgere og købere. Ved at tilbyde virtuelle ture hjælper vi med at
    eliminere unødvendige fremvisninger og sikre, at de interesserede parter virkelig er engagerede og seriøse.
    <br></br>
    <br></br>
    Vi er dedikerede til at skabe en mere effektiv, tidssparende og engagerende oplevelse i ejendomsverdenen. Giv os gerne et
    kald eller send en besked, hvis du ønsker at udforske mulighederne for at forbedre din ejendomspræsentation eller høre
    mere om, hvordan VR Estate kan hjælpe din virksomhed med at stå stærkere på det digitale marked. Lad os sammen omdanne
    den måde, vi ser på ejendomsbranchen.
    <br></br>
    <br></br>
    Velkommen til fremtiden med VR Estate!
  </>
);

function About() {
  const [showLongVersion, setShowLongVersion] = useState(false);

  const setShowVersion = (longVersion: boolean) => {
    setShowLongVersion(longVersion);
    scrollToPage('about')
  }

  return (
    <div className="about-container">
      <div className="about-container-wrapper">
        <div className="left-container">
          <h2 id="about">OM OS</h2>
          <h1 >VR Estate - Den virtuelle nøgle til succes!</h1>
          <p>{showLongVersion ? longVersion : shortVersion}</p>
          <ul>
            <li>
              <CheckIcon
                sx={{ fontSize: "13px", backgroundColor: "black", color: "white", borderRadius: "50px", padding: "4px" }}
              />
              <p>Bolig</p>
            </li>
            <li>
              <CheckIcon
                sx={{ fontSize: "13px", backgroundColor: "black", color: "white", borderRadius: "50px", padding: "4px" }}
              />
              <p>Erhverv</p>
            </li>
            <li>
              <CheckIcon
                sx={{ fontSize: "13px", backgroundColor: "black", color: "white", borderRadius: "50px", padding: "4px" }}
              />
              <p>Hotel</p>
            </li>
          </ul>
          <button id="read-more-button" className="read-more-button" onClick={() => setShowVersion(!showLongVersion)}>{`LÆS ${showLongVersion ? "MINDRE" : "MERE"
            }`}</button>
        </div>
        <div className="right-container">
          <img src={Spisestue} alt="" />
        </div>
      </div>
    </div>
  );
}

export default About;
