import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useMemo, useState } from 'react';
import { Tour } from './Projects';
import { dbDeleteTour, updateFavoriteTour, updateOrderNumbers, updateTour } from '../firebase';
import TourRow from '../components/TourRow';
import CreateEditTourDialog from '../components/CreateEditTourDialog';
import useConfirm from '../hooks/useConfirm';

interface Props {
  tours: Tour[];
}
export default function Admin(props: Props) {

  const [tours, setTours] = useState<Tour[]>([])
  const [editingTour, setEditingTour] = useState<Tour>();
  const [showDialog, setShowDialog] = useState(false);

  const [Dialog, confirm] = useConfirm('Er du sikker?', 'Er du sikker på at du vil slette denne tour?');

  useEffect(() => {
    setTours(props.tours.sort((a, b) => a.order - b.order));
  }, [props.tours])

  const updateTourHandler = (tour: Tour) => {
    if (!tour.id) return;
    updateTour(tour.id, tour).then(() => {
      setTours(prevTours => prevTours.map(_tour => _tour.id === tour.id ? tour : _tour))
      setOpenHandler(false);
    })
  }

  const deleteTourHandler = async (id: string) => {
    if (!id) return;

    const confirmed = await confirm();

    if (confirmed) {
      dbDeleteTour(id).then(() => {
        if (!id) return;
        setTours(prevTours => prevTours.filter(_tour => _tour.id !== id))
      })
    }
  }

  const setEditingTourHandler = (tour: Tour) => {
    setEditingTour(tour)
    setShowDialog(true)
  }

  const setOpenHandler = (open: boolean) => {
    if (!open) {
      setEditingTour(undefined)
    }
    setShowDialog(open);
  }

  const addTourHandler = (tour: Tour) => {
    setTours(prevTours => [...prevTours, tour]);
    setOpenHandler(false);
  }

  const swapElements = (index1: number, index2: number) => {
    if (!tours || index1 === tours.length || index2 === tours.length || index1 === -1 || index2 === -1) return;
    const array = [...tours];
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;

    const updatedTours = array.map((tour, index) => {
      return {
        ...tour,
        order: index + 1
      }
    })

    updateOrderNumbers(updatedTours).then(() => {
      setTours(updatedTours);
    });
  };

  const sortedTours = useMemo(() => {
    return tours.sort((a, b) => a.order - b.order)
  }, [tours])

  const setFavoriteTourHandler = (id: string) => {
    const updatedTours = tours.map(tour => {
      if (tour.id === id) {
        return {
          ...tour,
          showOnFrontpage: true
        }
      } else {
        return {
          ...tour,
          showOnFrontpage: false
        }
      }
    })

    updateFavoriteTour(updatedTours).then(() => {
      setTours(updatedTours);
    })
  }

  return (
    <>
      {Dialog()}
      <CreateEditTourDialog tours={tours} addTourCallback={addTourHandler} updateTourCallback={updateTourHandler} open={showDialog} setOpen={(open) => setOpenHandler(open)} editingTour={editingTour} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{/*Image*/}</TableCell>
              <TableCell align="left">{/*Favorite tour star*/}</TableCell>
              <TableCell align="left">Navn</TableCell>
              <TableCell align="left">Beskrivelse</TableCell>
              <TableCell align="left">Link</TableCell>
              <TableCell align="left">Vis på desktop</TableCell>
              <TableCell align="left">Vis på mobil</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTours.map((row, index) => (
              <TourRow setFavoriteTour={setFavoriteTourHandler} swapElements={swapElements} index={index} key={row.id} onEditTourHandler={setEditingTourHandler} tour={row} deleteTour={(id) => deleteTourHandler(id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}