import { initializeApp } from 'firebase/app';
import { collection, getFirestore, addDoc, query, getDocs, deleteDoc, doc, updateDoc, writeBatch } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { Tour } from './pages/Projects';
import { getStorage } from 'firebase/storage';



const firebaseConfig = {
    apiKey: "AIzaSyDaZkw4wt-Kp9lhX-ZMITTlOw4jrQkxmX0",
    authDomain: "vr-estate-5033c.firebaseapp.com",
    projectId: "vr-estate-5033c",
    storageBucket: "vr-estate-5033c.appspot.com",
    messagingSenderId: "602611933990",
    appId: "1:602611933990:web:a4eb02fef5cd080bab97e7",
    measurementId: "G-DWK15YZZ2N"
  };
  

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

const ref = collection(db, 'tours');

const addTour = async(message: any) => {
    const docRef = await addDoc(ref, message);
    return docRef.id;
}

const updateTour = async(tourId: string, tour: Tour) => {
    await updateDoc(doc(db, "tours", tourId), {...tour});
}

const updateOrderNumbers = async(tours: Tour[]) => {
    const batch = writeBatch(db);

    tours.forEach(tour => {
        batch.update(doc(db, "tours", tour.id ?? ''), {order: tour.order});
    });

    batch.commit();
}

const updateFavoriteTour = async(tours: Tour[]) => {
    const batch = writeBatch(db);

    tours.forEach(tour => {
        batch.update(doc(db, "tours", tour.id ?? ''), {showOnFrontpage: tour.showOnFrontpage});
    });

    batch.commit();
}

const getAllTours = async() : Promise<Array<Tour>> => {
    const q = query(collection(db, "tours"));

    const querySnapshot = await getDocs(q);
    const data: Array<any> = [];

    querySnapshot.forEach((doc) => {
        data.push(
            {
                ...doc.data(),
                id: doc.id,
            }
        )
      });
      return data;
}

const dbDeleteTour = (id: string) => {
    return deleteDoc(doc(db, "tours", id));
}

const auth = getAuth();

const signIn = async(email: string, password: string) : Promise<UserCredential> => {
    return await signInWithEmailAndPassword(auth, email, password);
}


export { addTour, getAllTours, dbDeleteTour, signIn, auth, storage, updateTour, updateOrderNumbers, updateFavoriteTour };