// @ts-nocheck
import LogoText from "../assets/images/logo-text.png";
import BgImage from "../assets/images/frontpage-bg.png";

interface Props {
  tours: Tour[];
}
function FrontPage(props: Props) {
  const { tours } = props;

  const favoriteTour = tours.find(tour => tour.showOnFrontpage);

  return (
    <div className="frontpage-container">
      <div className="left-container">
        <img className="logo-text" src={LogoText} alt="" />
        <img className="bg-image" src={BgImage} alt="" />
        <h1 id="frontpage">Løft din ejendoms-præsentation til det næste niveau!</h1>
        <p>
          Vi hjælper med at styrke din position i den hastigt udviklende digitale verden, samt forbedrer kundeoplevelsen med
          virtual tours.
        </p>
        {/*         <div className="contact-email-container">
          <div className="left-contact-email-container">
            <img src={Email} alt="Email logo" />
          </div>
          <div className="right-contact-email-container">
            <p>Kontakt os i dag!</p>
            <a href="mailto:kontakt@vr-estate.dk">kontakt@vr-estate.dk</a>
          </div>
        </div> */}
      </div>
      <div className="right-container">
        <div style={{ height: "100%", width: "95%" }}>
          <iframe
            id="frontpage-vr-demo"
            title="VR Estate"
            width="100%"
            height="100%"
            src={`https://vr-estate.captur3d.io/view/${favoriteTour?.src}`}
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking autoplay"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default FrontPage;
