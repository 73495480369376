import { Phone } from "@mui/icons-material";
import { scrollToPage } from "../utils";
import LogoText from "../assets/images/logo-text.png";
import MobileMenu from "./MobileMenu";

function Navigation() {


  return (
    <div className="navigation-container">
      <div className="left-container">
        <img className="logo-text" src={LogoText} alt="" />
      </div>
      <div className="center-container">
        <ul>
          <li className="active" onClick={() => scrollToPage("frontpage")}>
            Forside
          </li>
          <li onClick={() => scrollToPage("about")}>Om os</li>
          <li onClick={() => scrollToPage("projects")}>Projekter</li>
          <li onClick={() => scrollToPage("proces")}>Proces</li>
          <li onClick={() => scrollToPage("vision")}>Værdier</li>
          <li onClick={() => scrollToPage("contact")}>Kontakt</li>
        </ul>
      </div>
      <div className="right-container">
        <div className="phone-container">
          <Phone sx={{ fontSize: "16px", marginRight: "8px" }} fontSize="small" />
          <a href="tel:6031112298">+45 22 51 55 63</a>
        </div>
        <MobileMenu />
      </div>
    </div>
  );
}

export default Navigation;
