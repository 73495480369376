export interface ServiceType {
  imageSource: string;
  title: string;
  description: string;
}

function Service(props: ServiceType) {
  const { description, imageSource, title } = props;
  return (
    <div className="service">
      <img src={imageSource} />
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
}

export default Service;
