import { useEffect, useState } from "react";
import Stue from "../assets/images/stue.jpg";

const procesData = [
  {
    title: "Planlægning",
    subTitle: "Læs mere om vores planlægningsproces",
    steps: [
      {
        title: "1. Kundekonsultation",
        description:
          "Processen med en dybdegående behovsanalyse. Dette trin indebærer en grundig analyse af kundens behov, mål og præferencer. Vi lytter nøje til kundens ønsker og krav for at danne os et klart billede af, hvordan vi bedst kan imødekomme deres forventninger.",
      },
      {
        title: "2. Ejendomsforberedelse",
        description:
          "Vi kontakter kunden for at diskutere detaljerne i fotograferingsprocessen. Dette inkluderer fastsættelse af en passende dato og tidspunkt for fotograferingsdagen samt eventuelle specifikke krav fra kundens side. Vi vejleder også kunden i, hvordan de bedst kan forberede ejendommen til fotograferingen for at sikre det bedste resultat.",
      },
      {
        title: "3. Udstyr- og teknisk test",
        description:
          "For at sikre en problemfri fotograferingsdag sørger vi for, at alt udstyr er i optimal stand. Vi gennemgår en teknisk test af vores 360-graders teknologi og kameraudstyr for at sikre, at alt fungerer korrekt. Dette inkluderer også at kontrollere, at vores software og systemer er opdaterede og klar til brug på dagen.",
      },
      {
        title: "4. Lokaleinspektion",
        description:
          "Før vi begynder at scanne, foretager vi en nøje lokationsinspektion. Dette trin indebærer et besøg på ejendommen for at evaluere belysning, layout og eventuelle specifikke udfordringer. Ved at udføre denne inspektion kan vi identificere, hvordan vi bedst kan optage ejendommen for at skabe en flydende rute til din virtuelle tur.",
      },
    ],
    active: true,
  },
  {
    title: "Workflow",
    subTitle: "Læs mere om vores workflow",
    steps: [
      {
        title: "1. Kreativ fotografering",
        description:
          "Vores tilgang er at indfange ejendommen på en måde, der fremhæver dens unikke træk og skaber et indbydende og realistisk billede. Vi bruger 360-graders teknologi til at optage panoramaoptagelser fra forskellige vinkler og rum (som regel 2-4 billeder pr. rum). Vi sørger også for, at belysningen er optimal, for at opnå de bedste resultater.",
      },
      {
        title: "2. Udvælgelse og redigering",
        description:
          "Efter fotograferingen gennemgår vi omhyggeligt alle de optagne scanninger. Vores redigeringsteam forbedre farver, kontraster og detaljer og sikre, at billederne ser professionelle ud. Vi vælger de bedste optagelser for at inkludere i den virtuelle tur, og vi fjerner eventuelle uønskede elementer eller refleksioner for at sikre et fejlfrit resultat.",
      },
      {
        title: "3. Oprettelse af Virtual Tour",
        description:
          "Når billederne er redigeret og udvalgt, begynder vi processen med at oprette de virtuelle ture. Dette indebærer at lave og tilføje 3D modeller, navigationspunkter, plantegning, informationstekster, implementere jeres logo og interaktive funktioner, såsom panoramavinduer og muligheder for at zoome ind på detaljer.",
      },
    ],
    active: false,
  },
  {
    title: "Resultat",
    subTitle: "Læs mere om vores resultat",
    steps: [
      {
        title: "1. Unikke Virutal Tours",
        description:
          "Du får adgang til en eller flere virtuelle ture, der er skabt specielt til dine ejendomme. Disse ture giver dine potentielle købere og lejere mulighed for at udforske ejendommene i dybden og få en realistisk fornemmelse af rummene.",
      },
      {
        title: "2. Delingsmuligheder",
        description:
          "Vi leverer et direkte link til din virtuelle tur, som du nemt kan inkludere i dine online ejendomsannoncer eller på sociale medier. Dette gør det enkelt for dine potentielle kunder at få adgang til turen med blot ét klik.",
      },
      {
        title: "3. Embed-kode til websted",
        description:
          "Udover det direkte link til turen, får du også en indlejret HTML-kode. Denne kode kan du nemt kopiere og indsætte på din hjemmeside, så dine besøgende kan udforske virtuelle ture direkte fra din hjemmeside.",
      },
      {
        title: "4. Google Street View",
        description:
          "Hvis din virksomhed er understøttet af Google Street View, kan vi også uploade dine virtuelle ture til denne platform. Dette giver dig yderligere synlighed på internettet og hjælper med at nå ud til flere potentielle kunder.",
      },
    ],
    active: false,
  },
];

function Proces() {
  const [data, setData] = useState(procesData);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  useEffect(() => {
    const mappedData = data.map((obj, i) => (i === activeStepIndex ? { ...obj, active: true } : { ...obj, active: false }));
    setData(mappedData);
  }, [activeStepIndex]);

  return (
    <div className="proces-container">
      <div className="outer-container">
        <div className="left-container">
          <h1 id="proces">Vores Proces</h1>
          <div className="steps-container">
            {data.map((obj, index) => {
              return (
                <div style={{ borderBottom: `1px solid ${obj.active ? "#bfa47e" : "lightgrey"}` }} className="step">
                  <h3 onClick={() => setActiveStepIndex(index)} style={obj.active ? { color: "#bfa47e" } : {}}>
                    {obj.title}
                  </h3>
                </div>
              );
            })}
          </div>

          <h2>{data[activeStepIndex].subTitle}</h2>
          <div className="content-container">
            {data[activeStepIndex].steps.map((step) => {
              return (
                <div className="step-data">
                  <h4>{step.title}</h4>
                  <p>{step.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="right-container">
          <img src={Stue} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Proces;
